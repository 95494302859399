import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { MdOutlineMailOutline } from "react-icons/md";
import { IoLocation } from "react-icons/io5";

function Sec_C_1() {
    return (
        <>
            <div className='hed-2 text-center'>
                <h1 className='contat_titel'>Contact Us</h1>
            </div>
            <div className='Sec_H_2'>
                <Container>
                    <Row >
                        <Col lg={4} md={6} sm={12} >
                            <div className='Box-1'>
                                <div className='icon-1'>
                                    <a ><MdOutlinePhoneAndroid></MdOutlinePhoneAndroid></a>
                                </div>
                                <h4 className='pt-4'>Call Us</h4>
                                <a className='text-decoration-none' href='tel:+918140608316'>+91 9825131393</a>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className='Box-1'>
                                <div className='icon-1'>
                                    <a ><MdOutlineMailOutline></MdOutlineMailOutline></a>
                                </div>
                                <h4 className='pt-4'>Email Us</h4>
                                <a href='mailto:lakkadkaushal@gmail.com  ' className='text-decoration-none'>info@dynamicsoftlink.com</a>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className='Box-1'>
                                <div className='icon-1'>
                                    <a ><IoLocation></IoLocation></a>
                                </div>
                                <h4 className='pt-4'>Address</h4>
                                <a href='https://maps.app.goo.gl/o1Bha91Nbxb1FPRt6' target='_blank' className='text-decoration-none'>402, Empire State Building, Near Udhna Darwaja, Ring Road, 395002</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Sec_C_1