import React from 'react'
import Sec_A_1 from '../../About_Components/Sec_A_1'

function About() {
  return (
    <div>
        <Sec_A_1/>
    </div>
  )
}

export default About
