import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from "react-router-dom"
import Heder from './Components/Heder';
import Footer from './Components/Footer';
import Sec_H_1 from './Home_components/Sec_H_1';
import Sec_H_2 from './Home_components/Sec_H_2';
import Sec_H_3 from './Home_components/Sec_H_3';
import Sec_H_4 from './Home_components/Sec_H_4';
import Home from './pages/Home/Home';
import Sec_A_1 from './About_Components/Sec_A_1';
import About from './pages/About/About';
import Sec_C_1 from './Contact_Componenst/Sec_C_1';
import Contact from './pages/Contact/Contact';
import Sec_H_5 from './Home_components/Sec_H_5';
import Services from './pages/Services/Services';


function App() {
  return (

    <div>
      <Heder />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={ <About/>} />
        <Route path="/About" element={ <About/>} />
        <Route path="/Contact" element={ <Contact/>} />
        <Route path="/Services" element={ <Services/>} />
      </Routes>      
      <Footer />
    </div>

  );
}

export default App;
