import React from 'react'
import Sec_C_1 from '../../Contact_Componenst/Sec_C_1'

function Contact() {
  return (
    <div>
        <Sec_C_1/>
    </div>
  )
}

export default Contact