import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { IoIosPhonePortrait } from "react-icons/io";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { RiComputerLine } from "react-icons/ri";
import { IoLogoFigma } from "react-icons/io5";


function Sec_H_2() {
    return (
  
    <div className='Sec_H_2'>
        <Container>
            <Row>
                <Col lg={4} md={6} sm={12}>
                    <div className='Box-1 '>
                        <div className='icon-1'>
                            <a ><MdOutlinePhoneAndroid></MdOutlinePhoneAndroid></a>
                        </div>
                        <h4>App Development</h4>
                        <p>Deliver the digital experience your business deserves with expert 
                            development services. We provide end-to-end support, from concept to deployment.</p>
                    </div>
                </Col>
                <Col lg={4} md={6} sm={12}>
                    <div className='Box-1 '>
                        <div className='icon-1'>
                            <a ><RiComputerLine></RiComputerLine></a>
                        </div>
                        <h4>Software Development</h4>
                        <p>Empower your business with tailored software that drives success. From strategy to
                             implementation, we ensure every step meets your unique requirements.</p>
                    </div>
                </Col>
                <Col lg={4} md={6} sm={12}>
                    <div className='Box-1 '>
                        <div className='icon-1'>
                            <a ><IoLogoFigma></IoLogoFigma></a>
                        </div>
                        <h4>Product Design</h4>
                        <p>Transform ideas into market-ready products with our creative design solutions.
                         We blend innovation and functionality to deliver exceptional user experiences.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
    )
}

export default Sec_H_2