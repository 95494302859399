import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

function Sec_A_1() {
  return (
    <>
      <div className='hed'>
        <h1 className='About_titel'>About Us</h1>
      </div>
      <div className='sec_A_1'>
        <Container>
          <div className='A_Contain'>
            <div style={{ textAlign: "center" }} className='c-1'>
              <h4 className='pb-4'>We are dedicated to delivering innovative solutions.</h4>
              <p>Founded in 1999, Dynamic Softlink is a leading software solutions provider in the Textile Industry. The company is delivering innovative solutions across India. Established by
                Lalitkumar Periwal to organize an unorganized industry with the help of technology.</p>

              <ul className='c-2 m-0 p-0'>
                <li>
                  <h5 className='text-center text-decoration-underline pb-3 pt-4'>Our Mission</h5>
                  <h4 className='pb-4 '>System should work, individual should not.</h4>
                  <p>To develop and provide solutions for our clients that helps them in accessing data that are critical for their decision-making at the right time. We strive for developing a system that
                    forms a channel for communication and collaboration in our client’s enterprise.</p>
                </li>
              </ul>
              <ul className='c-2 p-0 m-0'>
                <li>
                  <h5 className='text-center text-decoration-underline pb-3 pt-4'>Our Vision</h5>
                  <p>To provide innovative software solutions that enable Textile Industry across India to deliver their
                    full potential to their clients and contribute in India’s mission of Digital India.</p>
                </li>
              </ul>
              <div className='A_btn'>
                <a href='#'>Explore More</a>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div>
        <Container>
          <Row className='pp'>
            <Col lg={3} md={4} sm={12} >
              <div className='BoxH_4'>
                <p className='p-0 m-0 pb-2 fs-3'>150+</p>
                <h4>Completed Projects</h4>
              </div>
            </Col>
            <Col lg={3} md={4} sm={12} >
              <div className='BoxH_4'>
                <p className='p-0 m-0 pb-2 fs-3'>1,200</p>
                <h4>Happy Customers</h4>
              </div>
            </Col>
            <Col lg={3} md={4} sm={12} >
              <div className='BoxH_4'>
                <p className='p-0 m-0 pb-2 fs-3'>50,000 hrs</p>
                <h4>Working Hours</h4>
              </div>
            </Col>
            <Col lg={3} md={4} sm={12} className=' pt-md-5 pt-lg-0'>
              <div className='BoxH_4'>
                <p className='p-0 m-0 pb-2 fs-3'>50</p>
                <h4>Awards Won</h4>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Sec_A_1