import React from 'react'
import Sec_H_4 from '../../Home_components/Sec_H_4'
import Sec_H_5 from '../../Home_components/Sec_H_5'

function Services() {
  return (
    <div>
        <Sec_H_4 />
        <Sec_H_5 />
    </div>
  )
}

export default Services
