import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function Sec_H_3() {

    return (
        <div className='Sec_H_3'>
            <Container>
                <Row className='main_Box'>
                    <Col lg={4} md={12} sm={12}>
                        <div className='About'>
                            <img src={require('../images/3.jpg')}></img>
                        </div>
                    </Col>
                    <Col lg={8} md={12} sm={12} className='hh'>
                        <div className='About_contain'>
                            <p>About Us</p>
                            <h3>We are dedicated to delivering innovative solutions.</h3>
                            <ul className='p-0 m-0'>
                                <li>Our focus is on creating efficient, cutting-edge technologies that bring lasting value
                                    to our clients. We embrace modern frameworks and agile methodologies
                                    that enhance collaboration and project success.</li>
                                <li>By aligning with our clients' goals, we ensure that every project is
                                    delivered on time, with the highest standards of quality, and adapted to future growth.</li>
                                <li>Our expertise guarantees swift response to challenges, delivers tailored solutions,
                                    and accelerates your business growth with advanced strategies and tools.</li>
                            </ul>
                            <h2>Explore More</h2>
                        </div>
                    </Col>
                </Row>
                <Row className='main_Box'>
                    <Col lg={4} md={12} sm={12}>
                        <div className='About'>
                            <img src={require('../images/9.jpg')}></img>
                        </div>
                    </Col>
                    <Col lg={8} md={12} sm={12} className='hh'>
                        <div className='About_contain'>
                            <p>Our Mission</p>
                            <h3>System should work, individual should not.</h3>
                            <ul className='p-0 m-0'>
                                <li>To develop and provide solutions for our clients that helps them in accessing data that are critical for their decision-making at the right time. We strive for
                                    developing a system that forms a channel for communication and collaboration in our client’s enterprise.</li>
                                <p>Our Vision</p>
                                <li>To provide innovative software solutions that enable Textile Industry across India to deliver
                                    their full potential to their clients and contribute in India’s mission of Digital India.</li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row className='main_Box' >
                    <Col lg={8} className='hh'>
                        <div className='About_contain'>
                            <p>PRODUCT DEVELOPMENT</p>
                            <h3>Building solutions that meet your vision.</h3>
                            <ul className='p-0 m-0'>
                                <li>We have a strong track record of working closely with clients to design and
                                    develop robust software products. Whether it's web or mobile applications,
                                    our team ensures that every project is aligned with your long-term business goals.</li>
                                <li>Our agile approach allows us to adapt to your evolving needs, delivering scalable,
                                    user-friendly solutions that grow with your business.</li>
                            </ul>
                            <div className='d-flex flex-wrap'>
                                <div className='pe-5'>
                                    <div className='product d-flex' >
                                        <img src={require('../images/5.png')} className='me-2' ></img>
                                        <h3>UI/UX Design</h3>
                                    </div>
                                    <div className='product d-flex' >
                                        <img src={require('../images/6.png')} className='me-2' ></img>
                                        <h3>Mobile App Development</h3>
                                    </div>
                                </div>
                                <div>
                                    <div className='product d-flex' >
                                        <img src={require('../images/7.png')}  className='me-2'  ></img>
                                        <h3>Software Testing</h3>
                                    </div>
                                    <div className='product d-flex' >
                                        <img src={require('../images/8.png')}  className='me-2'  ></img>
                                        <h3>Product Launch</h3>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className='About'>
                            <img src={require('../images/4.jpg')}></img>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Sec_H_3