import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Footer = () => {


    return (
        <>
            <Container className='footer-text'>
                <Row className='my-5 footer'>
             
                </Row >
                <Row className='space border-bottom m-sm-auto justify-content-center d-flex '>
                    <Col  lg={3} md={12} sm={12} ps-5  className='mt-md-4 text-center ' >
                        <h2>Services</h2>
                        <div className="box-fo-link d-flex justify-content-center ">
                            <ul>
                            <li><Link to={'/'}><a href="">Home</a></Link></li>
                                <li><Link to={'/About'}><a href="">About Us</a></Link></li>
                                <li><Link to={'/Contact'}><a href="">Contact</a></Link></li>
                                <li><Link to={'/Services'}><a href="">Services</a></Link></li>
                            </ul>
                           
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                        <div className="box-footer text-center">
                            <h3>Get in Touch</h3>
                            <p>Let’s talk. We will get in touch within 1 business day. No <br  /> obligation and we don’t share your data with anyone</p>
                            <div className="input d-flex justify-content-center mt-5">
                                <input type="text" className='footer_address' placeholder='  Your Email Address' />
                                <button>SUBSCRIBE</button>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={12} sm={12}   className='mt-md-4  text-center  text-lg-start  '>
                        <h2>Contact</h2>
                        <div className="box-fo-link">
                            <ul>
                                <li><h5>Mon – Fri : 9.00am 7.00pm</h5></li>
                                <li><h5>402, Empire State Building, Near Udhna Darwaja, Ring Road, Surat  395002 </h5></li>
                                <li><h5>(+91)9825131393 </h5></li>
                                <li><a href="#">info@dynamicsoftlink.com</a></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                {/* <Row>
                    <Col lg={12} className="text-center bottom my-3">
                        <h3>Copyright ©2019 Infinite. All Rights Reserved. <a href="/terms">Terms of Use</a></h3>
                    </Col>
                </Row> */}
            </Container>
        </>
    )
}

export default Footer
