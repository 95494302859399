import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Sec_H_4() {
  return (
    <>
      <div className="hed-2 text-center">
        <h1 className="contat_titel">Services</h1>
      </div>
      <div>
        <Container>
          <Row className="pp">
            <Col lg={3} md={4} sm={12}>
              <div className="BoxH_4">
                <p className="p-0 m-0 pb-2 fs-3">150+</p>
                <h4>Completed Projects</h4>
              </div>
            </Col>
            <Col lg={3} md={4} sm={12}>
              <div className="BoxH_4">
                <p className="p-0 m-0 pb-2 fs-3">1,200</p>
                <h4>Happy Customers</h4>
              </div>
            </Col>
            <Col lg={3} md={4} sm={12}>
              <div className="BoxH_4">
                <p className="p-0 m-0 pb-2 fs-3">50,000 hrs</p>
                <h4>Working Hours</h4>
              </div>
            </Col>
            <Col lg={3} md={4} sm={12} className=" pt-md-5 pt-lg-0">
              <div className="BoxH_4">
                <p className="p-0 m-0 pb-2 fs-3">50</p>
                <h4>Awards Won</h4>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="Main_Cart">
        <Container>
          <div className="justify-content-between flex-wrap d-flex pb-4 tt">
            <h4 className="ps-3 fs-3">Our featured projects</h4>
            {/* <ul className='nav pe-3 fs-6'>
                                <li className='p-3'>APP</li>
                                <li className='p-3'>DEVELOPMENT</li>
                                <li className='p-3'>UI DESIGN</li>
                            </ul> */}
          </div>
          <Row className="cart">
            <Col lg={4} md={6} className="mm pt-sm-4  pt-md-0  ">
              <img src={require("../images/11.jpg")}></img>
            </Col>
            <Col lg={4} md={6} className="mm pt-sm-4  pt-md-0 ">
              <img src={require("../images/12.jpg")}></img>
            </Col>
            <Col lg={4} md={6} className="mm pt-sm-4 pt-md-4 pt-lg-0 ">
              <img src={require("../images/13.jpg")}></img>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Sec_H_4;
