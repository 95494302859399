import React from 'react'
import Sec_H_1 from '../../Home_components/Sec_H_1'
import Sec_H_2 from '../../Home_components/Sec_H_2'
import Sec_H_3 from '../../Home_components/Sec_H_3'
import Sec_H_4 from '../../Home_components/Sec_H_4'
import Sec_H_5 from '../../Home_components/Sec_H_5'

function Home() {
  return (
    <div>
        <Sec_H_1/>
        <Sec_H_2/>
        <Sec_H_3/>
        <Sec_H_4/>
        <Sec_H_5/>
    </div>
  )
}

export default Home